import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Customer, SamplingLocation } from "../../../../shared/types/types";
import { truncate } from "../../../../shared/utilities/truncate";
import SamplingLocationForm from "./SamplingLocationForm";

const SamplingLocationEdit = () => (
  <Edit title={<SamplingLocationTitle />} component="div">
    <SamplingLocationForm edit formTitle="editar lugar de toma de muestra" />
  </Edit>
);

const SamplingLocationTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<SamplingLocation>();
  return record ? (
    <span>
      {`Lugar de Toma de Muestra ${truncate(record.name)}`}
    </span>
  ) : null;
};

export default SamplingLocationEdit;
