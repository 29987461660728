import { Datagrid, DateField, RaRecord, TextField } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreShowButton from "../../../../shared/components/core/CoreShowButton";
import sacopModules from "../../../../shared/utilities/sacopModules";

interface MobileGridProps {
    data? : RaRecord[]; 
}

const MobileGrid = (props: MobileGridProps) => {

    return (   
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="resources.sacop.fields.id"  variant="body1" />
            <TextField source="motive.motiveName" label="resources.sacop.fields.motive.motiveName" variant="body1" />
            <DateField source="dateRequestAction" label="resources.sacop.fields.dateRequestAction" variant="body1" />
            <DateField source="expirationDateAction" label="resources.sacop.fields.expirationDateAction" variant="body1" />
            <TextField source="closingStatus.statusName" label="resources.sacop.fields.actionStatus" variant="body1" />
            <TextField source="verificationStatus.statusName" label="resources.sacop.fields.verificationStatus" variant="body1" />

            <CoreEditButton permission="BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_U" actionModule={sacopModules.VERIFICATIONEFFECTIVENESSACTION} /> 
            <CoreShowButton permission="BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_L" />
        </Datagrid>
    );
}

MobileGrid.defaultProps = {
    data : {},
    ids : [],
};

export default MobileGrid;