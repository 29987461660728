import { Edit, useRecordContext, useTranslate } from "react-admin";
import { Sacop } from "../../../../shared/types/types";
import AnalysisOfSharesForm from "./AnalysisOfSharesForm";

export default function AnalysisOfSharesCreate() {
  return(
      <Edit title={<AnalysisOfSharesTitle />} component="div">
          <AnalysisOfSharesForm formTitle={<ActionEditTitle />} labelImpact={<LabelImpact />} edit={true}/>
      </Edit>
  );
}

const AnalysisOfSharesTitle = () => {
    const sacopRecord = useRecordContext<Sacop>();
    const translate = useTranslate();
    return sacopRecord ? (
        <span>
            {`${translate("resources.sacop.titles.analysisOfShanes.title")}`}
        </span>
    ) : null;
};

const ActionEditTitle = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.actions.analysisOfShares.edit.title")}`}
        </span>
    );
};

const LabelImpact = () => {
    const translate = useTranslate();
    return (
        <span>
            {`${translate("resources.sacop.fields.impactId")}`}
        </span>
    );
};