const periods = [
  {
    id: 1,
    name: "Anual"
  },
  {
    id: 2,
    name: "Bianual"
  },
  {
    id: 3,
    name: "Trianual"
  }
]

export default periods;
