import { Refresh } from "@mui/icons-material";
import { Button } from "@mui/material";
import fetchDataService from "../../../../shared/utilities/fetchs";
import { useNotify } from "react-admin";

export default function PasswordGenerator({email}) {
  const notify = useNotify();

  const handleClick = async (e) => {
    const {putRegeneratePassword} = fetchDataService();
    await putRegeneratePassword(email);
    notify("Contraseña regenerada", {
      type: "info",
    });
  }

  return (
    <Button
      onClick={handleClick}
      startIcon={<Refresh />}
      size="small"
      variant="outlined"
      style={{ textTransform: "none" }}
    >
      Regenerar contraseña
    </Button>
  );
}
