import { Datagrid, EmailField, Loading, RaRecord, TextField, useListContext, useTranslate } from "react-admin";
import CoreEditButton from "../../../../shared/components/core/CoreEditButton";
import CoreDeleteButton from "../../../../shared/components/core/CoreDeleteButton";
import { Instrument } from "../../../../shared/types/types";
import sacopModules from "../../../../shared/utilities/sacopModules";
import NoDataFound from "../../../../shared/components/noDataFound/NoDataFound";

interface MobileGridProps {
    data?: RaRecord[];
}

const MobileGrid = (props: MobileGridProps) => {

    const { data, isLoading } = useListContext<Instrument>();
    

    if (isLoading || data.length === 0) {
        if (data?.length === 0)
            return <NoDataFound />
        return <Loading />;
    }

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField label="resources.instrument.fields.name" source="name" variant="body1" />
            <TextField label="resources.instrument.fields.code" source="code" variant="body1" />
            <TextField label="resources.instrument.fields.brand" source="brand" variant="body1" />
            <TextField label="resources.instrument.fields.model" source="model" variant="body1" />
            
            <CoreEditButton permission="BACKOFFICE_INSTRUMENT_U" actionModule={sacopModules.NONE} />
            <CoreDeleteButton permission="BACKOFFICE_INSTRUMENT_D" />
        </Datagrid>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
