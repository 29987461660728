import { useEffect, useState } from "react";
import {
  BooleanInput,
  TabbedForm,
  TextInput,
  required,
  useEditContext,
  useGetList,
  Loading,
  SelectInput,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import CalibrationInputGroup from "./CalibrationInputGroup";
import { Instrument } from "../../../../shared/types/types";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";
import { Typography } from "@mui/material";

const InstrumentEditForm = () => {
  const { record: instrument, isLoading } = useEditContext<Instrument>();

  const [requireCalibration, setRequireCalibration] = useState<Boolean>(false);

  /*
  const { data: localizations, isLoading: localizationsIsLoading } = useGetList(
    "localization",
    {
      sort: { field: "name", order: "DESC" },
    }
  );
  */

  useEffect(() => {
    setRequireCalibration(instrument!.requireCalibration);
  }, [isLoading]);

  /*
  if (localizationsIsLoading || localizationsIsLoading) {
    return <Loading />;
  }
  */

  const optionRenderer = localization => `${localization.code} - ${localization.name}`;

  return (
    <SimpleForm toolbar={<FormToolbar edit />} defaultValues={{ id: "0000-0000-0000-0000" }} sx={{ mt: 3 }}>
      <Grid container lg={10} lgOffset={1} columnSpacing={2}>
        <Grid xs={12}>
          <Typography variant="h6" sx={{ mb: 2}} >
            EDITAR INSTRUMENTO
          </Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="name"
            label="resources.instrument.fields.name"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="code"
            label="resources.instrument.fields.code"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="brand"
            label="resources.instrument.fields.brand"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="model"
            label="resources.instrument.fields.model"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          {/*
          <SelectInput
            source="costCenterId"
            label="Centro de Costo"
            choices={localizations}
            optionText={optionRenderer}
            fullWidth
            validate={required()}
          />
          */}
          <ReferenceArrayInput 
            source="costCenterId" 
            reference="localization"
            perPage={999}
            filter={{ active: true }}
            >
            <AutocompleteArrayInput 
              label="Centro de costo"
              optionText={(choice) => `${choice.code} - ${choice.name}`}
              debounce={500}
            />
          </ReferenceArrayInput>
        </Grid>
        <Grid xs={12} md={6} sx={{display: "flex"}}>
          <BooleanInput
            source="active"
            label="resources.instrument.fields.active"
            fullWidth
            validate={required()}
          />
          <BooleanInput
            onChange={(e) => {
              setRequireCalibration(e.target.checked);
            }}
            source="requireCalibration"
            label="resources.instrument.fields.require_calibration"
            fullWidth
            validate={required()}
          />
        </Grid>
        {requireCalibration && <CalibrationInputGroup />}
      </Grid>
    </SimpleForm>
  );
};

export default InstrumentEditForm;
