import { useState } from 'react';
import Box from '@mui/material/Box';

import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState } from 'react-admin';

import SubMenu from './SubMenu';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BookIcon from '@mui/icons-material/Book';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FlakyIcon from '@mui/icons-material/Flaky';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { AccountTree, AdminPanelSettings, Apartment, ArrowOutward, DirectionsBoat, Handyman, LocationOn, Warehouse } from '@mui/icons-material';

type MenuName = 'menuBackoffice' | 'menuOperation' | 'menuAdministration' | 'menuSacop';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuBackoffice: true,
        menuOperation: true,
        menuAdministration: true,
        menuSacop: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem
              sx={{'&:hover':{backgroundColor: 'secondary.main', color: 'primary.contrastText'}}}
            />

            <SubMenu
                handleToggle={() => handleToggle('menuAdministration')}
                isOpen={state.menuAdministration}
                name="resources.menu.admin.name"
                icon={<BusinessIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/user"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.admin.user.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<SupportAgentIcon />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/rol"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.admin.rol.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AdminPanelSettings />}
                    dense={dense}
                />

            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuBackoffice')}
                isOpen={state.menuBackoffice}
                name="resources.menu.backoffice.name"
                icon={<BusinessIcon />}
                dense={dense}
            >
                

                <MenuItemLink
                    to="/localization"
                    state={{ _scrollToTop: true }}
                    primaryText="Centro de Costo"
                    leftIcon={<Apartment />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/laboratory"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.laboratory.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<BiotechIcon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/instrument"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.instrument.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<Handyman />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/exporter"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.exporter.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ArrowOutward />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/customsHouse"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.customs_house.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<Warehouse />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/port"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.port.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<DirectionsBoat />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/samplingLocation"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.sampling_location.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LocationOn />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/normative"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.menu.backoffice.normative.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AccountTree />}
                    dense={dense}
                />

            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuOperation')}
                isOpen={state.menuOperation}
                name="resources.menu.forms.name"
                icon={<AssignmentIcon />}
                dense={dense}
            >
            <MenuItemLink
                to="/weightReportOperation"
                state={{ _scrollToTop: true }}
                leftIcon={<AssignmentIcon />}
                primaryText="resources.menu.forms.weight_report_operation.name"
                dense={dense}
            />
            <MenuItemLink
                to="/laboratoryRegistry"
                state={{ _scrollToTop: true }}
                leftIcon={<ScienceIcon />}
                primaryText="resources.menu.forms.laboratory.name"
                dense={dense}
            />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuSacop')}
                isOpen={state.menuSacop}
                name="resources.menu.sacop.name"
                icon={<AssignmentIcon />}
                dense={dense}
            >
            <MenuItemLink
                to="/requestForAction"
                state={{ _scrollToTop: true }}
                leftIcon={<BookIcon />}
                primaryText="resources.menu.backoffice.requestForAction.name"
                dense={dense}
            />
            <MenuItemLink
                to="/analysisOfShares"
                state={{ _scrollToTop: true }}
                leftIcon={<NoteAltIcon />}
                primaryText="resources.menu.backoffice.analysisOfShares.name"
                dense={dense}
            />
            <MenuItemLink
                to="/implementationOfAction"
                state={{ _scrollToTop: true }}
                leftIcon={<Diversity3Icon />}
                primaryText="resources.menu.backoffice.implementationOfAction.name"
                dense={dense}
            />
            <MenuItemLink
                to="/closingOfShares"
                state={{ _scrollToTop: true }}
                leftIcon={<FlakyIcon />}
                primaryText="resources.menu.backoffice.closingOfShares.name"
                dense={dense}
            />
            <MenuItemLink
                to="/verificationEffectivenessAction"
                state={{ _scrollToTop: true }}
                leftIcon={<ContentPasteSearchIcon />}
                primaryText="resources.menu.backoffice.verificationEffectivenessAction.name"
                dense={dense}
            />
            <MenuItemLink
                to="/masterOfShares"
                state={{ _scrollToTop: true }}
                leftIcon={<AssignmentIndIcon />}
                primaryText="resources.menu.backoffice.masterOfShares.name"
                dense={dense}
            />
            <MenuItemLink
                to="/sharesCommitee"
                state={{ _scrollToTop: true }}
                leftIcon={<Diversity2Icon />}
                primaryText="resources.menu.backoffice.sharesCommitee.name"
                dense={dense}
            />
            <MenuItemLink
                to="/sacop"
                state={{ _scrollToTop: true }}
                leftIcon={<DashboardIcon />}
                primaryText="resources.menu.backoffice.sacop.name"
                dense={dense}
            />

            </SubMenu>
        </Box>
    );
};

export default Menu;
