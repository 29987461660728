import {
  TabbedForm,
  TextInput,
  required,
  BooleanInput,
  Loading,
  AutocompleteInput,
  email,
  SimpleForm,
} from "react-admin";
import AddressInput from "../../../../shared/components/direction/AddressInput";
import useUser from "../../../../shared/hooks/useUsers";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import FormToolbar from "../../../../shared/components/toolbar/FormToolbar";

interface CustomsHouseProps {
  edit?: boolean;
  formTitle: string;
}

const CustomsHouseForm = ({ edit, formTitle }: CustomsHouseProps) => {
  const { dataUser, isLoadingUser } = useUser();

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <SimpleForm
      toolbar={<FormToolbar edit={edit ? true : undefined} />}
      sx={{ mt: 2 }}
    >
      <Grid container lg={10} lgOffset={1} columnSpacing={2}>
        <Grid xs={12}>
          <Typography sx={{ mb: 2 }} variant="h6">
            {formTitle.toUpperCase()}
          </Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="name"
            label="resources.customs_house.fields.name"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="code"
            label="resources.customs_house.fields.code"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="contactName"
            label="resources.customs_house.fields.contact_name"
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="contactPhone"
            label="resources.customs_house.fields.contact_phone"
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <TextInput
            source="contactEmail"
            label="resources.customs_house.fields.contact_email"
            validate={email()}
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <AutocompleteInput
            source="ahkContactId"
            label="resources.customs_house.fields.ahk_contact"
            choices={
              dataUser
                ? dataUser.map((user) => ({
                    id: user.mail,
                    name: user.mail,
                  }))
                : []
            }
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <BooleanInput
            source="active"
            label="resources.customs_house.fields.active"
          />
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography sx={{mb: 1}} variant="h6">Dirección</Typography>
          </Grid>
          <Grid xs={12} md={6}>
            <AddressInput />
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default CustomsHouseForm;
