export default {
    BACKOFFICE_CUSTOMER: [
        { id: "BACKOFFICE_CUSTOMER_C", name: "Crear Clientes" },
        { id: "BACKOFFICE_CUSTOMER_R", name: "Leer Clientes" },
        { id: "BACKOFFICE_CUSTOMER_U", name: "Actualizar Clientes" },
        { id: "BACKOFFICE_CUSTOMER_D", name: "Borrar Clientes" },
    ],
    BACKOFFICE_COMPANY: [
        ,
        { id: "BACKOFFICE_COMPANY_C", name: "Crear Compañías" },
        { id: "BACKOFFICE_COMPANY_R", name: "Leer Compañías" },
        { id: "BACKOFFICE_COMPANY_U", name: "Actualizar Compañías" },
        { id: "BACKOFFICE_COMPANY_D", name: "Borrar Compañías" },
    ],
    BACKOFFICE_SECTOR: [
        { id: "BACKOFFICE_SECTOR_C", name: "Crear Rubros" },
        { id: "BACKOFFICE_SECTOR_R", name: "Leer Rubros" },
        { id: "BACKOFFICE_SECTOR_U", name: "Actualizar Rubros" },
        { id: "BACKOFFICE_SECTOR_D", name: "Borrar Rubros" },
    ],
    BACKOFFICE_USER: [
        { id: "BACKOFFICE_USER_C", name: "Crear Usuarios" },
        { id: "BACKOFFICE_USER_R", name: "Leer Usuarios" },
        { id: "BACKOFFICE_USER_U", name: "Actualizar Usuarios" },
        { id: "BACKOFFICE_USER_D", name: "Borrar Usuarios" },
    ],
    BACKOFFICE_ROLE: [
        { id: "BACKOFFICE_ROLE_C", name: "Crear Roles" },
        { id: "BACKOFFICE_ROLE_R", name: "Leer Roles" },
        { id: "BACKOFFICE_ROLE_U", name: "Actualizar Roles" },
        { id: "BACKOFFICE_ROLE_D", name: "Borrar Roles" },
    ],

    BACKOFFICE_NORMATIVE: [
        { id: "BACKOFFICE_NORMATIVE_C", name: "Crear Normativas" },
        { id: "BACKOFFICE_NORMATIVE_R", name: "Leer Normativas" },
        { id: "BACKOFFICE_NORMATIVE_U", name: "Actualizar Normativas" },
        { id: "BACKOFFICE_NORMATIVE_D", name: "Borrar Normativas" },
    ],
    BACKOFFICE_LOCALIZATION: [
        { id: "BACKOFFICE_LOCALIZATION_C", name: "Crear Localizaciones" },
        { id: "BACKOFFICE_LOCALIZATION_R", name: "Leer Localizaciones" },
        { id: "BACKOFFICE_LOCALIZATION_U", name: "Actualizar Localizaciones" },
        { id: "BACKOFFICE_LOCALIZATION_D", name: "Borrar Localizaciones" },
    ],
    BACKOFFICE_CUSTOMS: [
        { id: "BACKOFFICE_CUSTOMS_C", name: "Crear Aduanas" },
        { id: "BACKOFFICE_CUSTOMS_R", name: "Leer Aduanas" },
        { id: "BACKOFFICE_CUSTOMS_U", name: "Actualizar Aduanas" },
        { id: "BACKOFFICE_CUSTOMS_D", name: "Borrar Aduanas" },
    ],
    BACKOFFICE_LABORATORY: [
        { id: "BACKOFFICE_LABORATORY_C", name: "Crear Laboratorios" },
        { id: "BACKOFFICE_LABORATORY_R", name: "Leer Laboratorios" },
        { id: "BACKOFFICE_LABORATORY_U", name: "Actualizar Laboratorios" },
        { id: "BACKOFFICE_LABORATORY_D", name: "Borrar Laboratorios" },
    ],
    BACKOFFICE_EXPORTER: [
        { id: "BACKOFFICE_EXPORTER_C", name: "Crear Exportadores" },
        { id: "BACKOFFICE_EXPORTER_R", name: "Leer Exportadores" },
        { id: "BACKOFFICE_EXPORTER_U", name: "Actualizar Exportadores" },
        { id: "BACKOFFICE_EXPORTER_D", name: "Borrar Exportadores" },
    ],
    BACKOFFICE_LABORATORY_REGISTRY: [
        { id: "BACKOFFICE_LABORATORY_REGISTRY_C", name: "Crear Registros de Laboratorio" },
        { id: "BACKOFFICE_LABORATORY_REGISTRY_R", name: "Leer Registros de Laboratorio" },
        { id: "BACKOFFICE_LABORATORY_REGISTRY_U", name: "Actualizar Registros de Laboratorio" },
        { id: "BACKOFFICE_LABORATORY_REGISTRY_D", name: "Borrar Registros de Laboratorio" },
    ],
    BACKOFFICE_INSTRUMENT: [
        { id: "BACKOFFICE_INSTRUMENT_C", name: "Crear Instrumentos" },
        { id: "BACKOFFICE_INSTRUMENT_R", name: "Leer Instrumentos" },
        { id: "BACKOFFICE_INSTRUMENT_U", name: "Actualizar Instrumentos" },
        { id: "BACKOFFICE_INSTRUMENT_D", name: "Borrar Instrumentos" },
    ],
    BACKOFFICE_PORT: [
        { id: "BACKOFFICE_PORT_C", name: "Crear Puertos" },
        { id: "BACKOFFICE_PORT_R", name: "Leer Puertos" },
        { id: "BACKOFFICE_PORT_U", name: "Actualizar Puertos" },
        { id: "BACKOFFICE_PORT_D", name: "Borrar Puertos" },
    ],
    BACKOFFICE_WEIGHT_REPORT_OPERATION: [
        { id: "BACKOFFICE_WEIGHT_REPORT_OPERATION_C", name: "Crear Registro de exportación - Inf. Peso" },
        { id: "BACKOFFICE_WEIGHT_REPORT_OPERATION_R", name: "Leer Registro de exportación - Inf. Peso" },
        { id: "BACKOFFICE_WEIGHT_REPORT_OPERATION_U", name: "Actualizar Registro de exportación - Inf. Peso" },
        { id: "BACKOFFICE_WEIGHT_REPORT_OPERATION_D", name: "Borrar Registro de exportación - Inf. Peso" },
    ],
    BACKOFFICE_SAMPLING_LOCATION: [
        { id: "BACKOFFICE_SAMPLING_LOCATION_C", name: "Crear Lugar de Toma de Muestra" },
        { id: "BACKOFFICE_SAMPLING_LOCATION_R", name: "Leer Lugar de Toma de Muestra" },
        { id: "BACKOFFICE_SAMPLING_LOCATION_U", name: "Actualizar Lugar de Toma de Muestra" },
        { id: "BACKOFFICE_SAMPLING_LOCATION_D", name: "Borrar Lugar de Toma de Muestra" },
    ],
    BACKOFFICE_SACOP: [
        { id: "BACKOFFICE_SACOP_C", name: "Crear Solicitud de Acción" },
        { id: "BACKOFFICE_SACOP_R", name: "Leer Solicitud de Acción" },
        { id: "BACKOFFICE_SACOP_U", name: "Actualizar Solicitud de Acción" },
        { id: "BACKOFFICE_SACOP_D", name: "Borrar Solicitud de Acción" },
    ],
    BACKOFFICE_REQUEST_FOR_ACTION: [
        { id: "BACKOFFICE_REQUEST_FOR_ACTION_C", name: "Crear Solicitud de Acción" },
        { id: "BACKOFFICE_REQUEST_FOR_ACTION_R", name: "Leer Solicitud de Acción" },
        { id: "BACKOFFICE_REQUEST_FOR_ACTION_U", name: "Actualizar Solicitud de Acción" },
        { id: "BACKOFFICE_REQUEST_FOR_ACTION_S", name: "Enviar Solicitud a Responsable" },
        { id: "BACKOFFICE_REQUEST_FOR_ACTION_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_ANALYSIS_OF_SHARES: [
        { id: "BACKOFFICE_ANALYSIS_OF_SHARES_R", name: "Leer Cierre de Acciones" },
        { id: "BACKOFFICE_ANALYSIS_OF_SHARES_U", name: "Actualizar Cierre de Acciones" },
        { id: "BACKOFFICE_ANALYSIS_OF_SHARES_S", name: "Enviar Solicitud al Responsable" },
        { id: "BACKOFFICE_ANALYSIS_OF_SHARES_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_IMPLEMENTATION_OF_ACTION: [
        { id: "BACKOFFICE_IMPLEMENTATION_OF_ACTION_R", name: "Leer Implementación de Acciones" },
        { id: "BACKOFFICE_IMPLEMENTATION_OF_ACTION_U", name: "Actualizar Implementación de Acciones" },
        { id: "BACKOFFICE_IMPLEMENTATION_OF_ACTION_S", name: "Enviar Solicitud al Responsable" },
        { id: "BACKOFFICE_IMPLEMENTATION_OF_ACTION_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_CLOSING_OF_SHARES: [
        { id: "BACKOFFICE_CLOSING_OF_SHARES_R", name: "Leer Cierre de Acciones" },
        { id: "BACKOFFICE_CLOSING_OF_SHARES_U", name: "Actualizar Cierre de Acciones" },
        { id: "BACKOFFICE_CLOSING_OF_SHARES_S", name: "Enviar Solicitud al Responsable" },
        { id: "BACKOFFICE_CLOSING_OF_SHARES_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION: [
        { id: "BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_R", name: "Leer Verificación Eficiencia Acción" },
        { id: "BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_U", name: "Actualizar Verificación Eficiencia Acción" },
        { id: "BACKOFFICE_VERIFICATION_EFFECTIVENESS_ACTION_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_MASTER_OF_SHARES: [
        { id: "BACKOFFICE_MASTER_OF_SHARES_R", name: "Leer Maestro de Acción" },
        { id: "BACKOFFICE_MASTER_OF_SHARES_U", name: "Actualizar Maestro de Acción" },
        { id: "BACKOFFICE_MASTER_OF_SHARES_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_SHARES_COMMITEE: [
        { id: "BACKOFFICE_SHARES_COMMITEE_R", name: "Leer Comité de Acción" },
        { id: "BACKOFFICE_SHARES_COMMITEE_L", name: "Ver Detalle" },
    ],
    BACKOFFICE_SACOP_DASHBOARD: [
        { id: "BACKOFFICE_SACOP_DASHBOARD_R", name: "Leer Dashboard" },
        { id: "BACKOFFICE_SACOP_DASHBOARD_L", name: "Ver Detalle" },
    ],
};

